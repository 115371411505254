<template>
  <div v-if="products">
    <h1>Shop</h1>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div v-for="(product, index) in sorted" :key="index">
        <router-link class="no-underline" :to="{ name: 'Product', params: { slug: product.slug }}">
          <img v-if="product.featured.image" :src="api + product.featured.image.formats.medium.url"/>
          <h2 v-html="product.title"></h2>
          <div class="py-2" v-if="product.featured.excerpt">
            {{ product.featured.excerpt }}
          </div>
        </router-link>
        <button class="bg-black text-white rounded-full outline-none px-2" @click="addProductToCart(product)">
          Add to cart
        </button>
        <span class="px-2">{{ product.unitPrice | currency }}</span>
      </div>
    </div>
    <slot/>
  </div>
</template>

<script>

export default {
  name: 'Shop',
  metaInfo: {
    title: 'Products'
  },
  data() {
    return {
      api: process.env.VUE_APP_API_URL,
      loading: false
    }
  },
  computed: {
    products() {
      return this.$store.getters.products
    },
    sorted() {
      const arr = this.products
      return arr.sort((a, b) => (a.slug > b.slug) - (a.slug < b.slug))
    }
  },
  methods: {
    addProductToCart(product) {
      this.$store.dispatch('addProductToCart', product)
    }
  },
  created() {
    this.loading = true
    this.$store.dispatch('fetchProducts')
      .then(() => {
        this.loading = false
      })
  }
}
</script>